import { ecoSocialData } from "../../utils/constants";
import EcoSocialLogo from "../../assets/new/eco-social/eco-social-logo.png";
import EcoSocialBgRight from "../../assets/new/eco-social/eco-social-bg-right.png";
import "./index.css";

// TODO: This file is a first step in orgranising this code base.
// Initially everything was just dumped into Landing/index.js which made it very hard to maintain.
// In the near future, we want to simplify this code and make this component responsive with media queries
// as opposed to using isMobileView to keep our code DRY.

export default function EcoSocial({
  ecoSocialRef,
  logoActive,
  onSocialCardSelect,
  socialCardSelected,
  isMobileView,
}) {
  const getMobileOrder = () => {
    let mobileOrder = [];
    ecoSocialData.forEach((item, i) => {
      if (i === 3) mobileOrder.push(ecoSocialData[5]);
      else if (i === 5) mobileOrder.push(ecoSocialData[3]);
      else if (i === 6) mobileOrder.push(ecoSocialData[8]);
      else if (i === 8) mobileOrder.push(ecoSocialData[6]);
      else mobileOrder.push(item);
    });
    return mobileOrder;
  };

  return isMobileView ? (
    <div
      className="C-Eco-Full-Section eco-social-mobile-container"
      ref={ecoSocialRef}
    >
      <div className="eco-social-logo">
        <img src={EcoSocialLogo} alt="eco social logo" />
        <svg
          className={`eco-title-logo ${logoActive.social ? "active" : ""}`}
          width="178"
          height="22"
          viewBox="0 0 178 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.72866 12.0318C3.19019 9.83133 8.67097 8.13517 11.2287 7.56214C22.921 4.81165 41.7383 3.43627 49.594 4.12396C57.4498 4.81165 48.8633 6.18681 41.3729 7.56208C33.8825 8.93735 39.3633 9.62498 46.1229 8.93735C52.8825 8.24971 66.9498 7.56208 79.0075 7.21826C91.0652 6.87444 78.8248 6.18681 66.9498 6.18681C55.0748 6.18681 55.2575 4.8116 59.4594 4.12396C63.6614 3.43633 73.1614 0.685786 84.3056 1.0296C95.4498 1.37342 121.758 2.06106 133.084 4.46778C144.411 6.8745 167.796 13.407 173.46 15.4699C179.123 17.5328 178.392 23.3777 168.344 19.9396C158.296 16.5014 137.469 10.3127 91.796 9.28122C46.1229 8.24977 11.4113 14.0947 7.75748 16.5014C4.10363 18.9081 1.54594 18.9081 3.00748 16.8452C4.46902 14.7823 4.83443 14.4385 3.37289 15.1261C1.91135 15.8138 -0.098269 14.7823 1.72866 12.0318Z"
            fill="#743AEC"
            stroke="#743AEC"
            className="svg-elem-1"
          ></path>
        </svg>
      </div>
      <div className="eco-social-grid-container">
        {getMobileOrder().map((item, index) => (
          <div
            className={`eco-social-item ${
              socialCardSelected[index] ? "active" : ""
            }`}
            key={item.title}
            style={{ outlineColor: item.color }}
            onClick={() => onSocialCardSelect(index)}
          >
            <div className="eco-social-item-top">
              <img
                className="eco-social-icon"
                src={item.icon}
                alt={`${item.title} icon`}
              />
              {socialCardSelected[index] && (
                <button className="primary-btn btn-try poppins-regular">
                  Try it out
                </button>
              )}
            </div>
            <div className="eco-social-title poppins-semibold">
              {item.title}
            </div>
            <div className="eco-social-description poppins-regular">
              {item.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div className="C-Eco-Full-Section eco-social-container" ref={ecoSocialRef}>
      <div className="eco-social-content">
        <div className="eco-social__heading-container">
          <div className="eco-social-logo">
            <img src={EcoSocialLogo} alt="eco social logo" />
            <svg
              className={`eco-title-logo ${logoActive.social ? "active" : ""}`}
              width="178"
              height="22"
              viewBox="0 0 178 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.72866 12.0318C3.19019 9.83133 8.67097 8.13517 11.2287 7.56214C22.921 4.81165 41.7383 3.43627 49.594 4.12396C57.4498 4.81165 48.8633 6.18681 41.3729 7.56208C33.8825 8.93735 39.3633 9.62498 46.1229 8.93735C52.8825 8.24971 66.9498 7.56208 79.0075 7.21826C91.0652 6.87444 78.8248 6.18681 66.9498 6.18681C55.0748 6.18681 55.2575 4.8116 59.4594 4.12396C63.6614 3.43633 73.1614 0.685786 84.3056 1.0296C95.4498 1.37342 121.758 2.06106 133.084 4.46778C144.411 6.8745 167.796 13.407 173.46 15.4699C179.123 17.5328 178.392 23.3777 168.344 19.9396C158.296 16.5014 137.469 10.3127 91.796 9.28122C46.1229 8.24977 11.4113 14.0947 7.75748 16.5014C4.10363 18.9081 1.54594 18.9081 3.00748 16.8452C4.46902 14.7823 4.83443 14.4385 3.37289 15.1261C1.91135 15.8138 -0.098269 14.7823 1.72866 12.0318Z"
                fill="#743AEC"
                stroke="#743AEC"
                className="svg-elem-1"
              ></path>
            </svg>
          </div>
          <h2 className="eco-social__heading section-title poppins-bold">
            The social network engagement toolbox.
          </h2>
        </div>
        <div className="eco-social__container">
          <div className="eco-social-grid-container">
            {ecoSocialData.map((item, index) => (
              <div
                className={`eco-social-item ${
                  socialCardSelected[index] ? "active" : ""
                }`}
                key={index}
                style={{ outlineColor: item.color }}
                onClick={() => onSocialCardSelect(index)}
              >
                <div className="eco-social-item-top">
                  <img
                    className="eco-social-icon"
                    src={item.icon}
                    alt={`${item.title} icon`}
                  />
                  {socialCardSelected[index] && (
                    <button className="primary-btn btn-try poppins-regular">
                      Try it out
                    </button>
                  )}
                </div>
                <div className="eco-social-title poppins-semibold">
                  {item.title}
                </div>
                <div className="eco-social-description poppins-regular">
                  {item.description}
                </div>
              </div>
            ))}
          </div>
          <div className="eco-social-bg">
            <img src={EcoSocialBgRight} alt="eco social bg" />
          </div>
        </div>
      </div>
    </div>
  );
}
