import "./index.css";
import React from "react";
import EcoGameContentMobile from "../../assets/new/eco-game/eco-game-content-mobile.png";
import EcoGameMobileImg from "../../assets/new/eco-game/eco-game-mobile.png";
import EcoGameLogo from "../../assets/new/eco-game-logo.png";
import ArrowImg from "../../assets/new/Arrow.png";
import EcoGameLeft from "../../assets/new/eco-game-left.png";
import EcoGameRight from "../../assets/new/eco-game-right.png";

// TODO: This file is a first step in orgranising this code base.
// Initially everything was just dumped into Landing/index.js which made it very hard to maintain.
// In the near future, we want to simplify this code and make this component responsive with media queries
// as opposed to using isMobileView to keep our code DRY.

export default function EcoGame({ isMobileView, logoActive, ecoGameRef }) {
  return isMobileView ? (
    <div
      className="C-Eco-Full-Section eco-game-mobile-container"
      ref={ecoGameRef}
    >
      {/* <div className="eco-game-logo">
        <img src={EcoGameLogo} alt="eco game logo" />
        <svg
          className={`eco-title-logo ${logoActive.game ? "active" : ""}`}
          width="172"
          height="21"
          viewBox="0 0 172 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.70381 11.4802C3.11553 9.38976 8.40946 7.77841 10.88 7.23403C22.1737 4.62107 40.3495 3.31446 47.9374 3.96776C55.5254 4.62107 47.2316 5.92747 39.9965 7.23398C32.7615 8.54048 38.0554 9.19374 44.5846 8.54048C51.1138 7.88723 64.7015 7.23398 76.3482 6.90735C87.9948 6.58072 76.1717 5.92747 64.7015 5.92747C53.2314 5.92747 53.4078 4.62102 57.4665 3.96776C61.5252 3.31451 70.7013 0.701497 81.4656 1.02812C92.23 1.35475 117.641 2.008 128.582 4.29439C139.522 6.58078 162.11 12.7867 167.58 14.7464C173.051 16.7062 172.345 22.2589 162.639 18.9926C152.934 15.7263 132.817 9.84704 88.7007 8.86716C44.5846 7.88728 11.0564 13.4399 7.52711 15.7263C3.99782 18.0127 1.52732 18.0127 2.93904 16.0529C4.35075 14.0932 4.70371 13.7666 3.29199 14.4198C1.88028 15.0731 -0.060828 14.0932 1.70381 11.4802Z"
            fill="#F9FBB3"
            stroke="#F9FBB3"
            className="svg-elem-1"
          ></path>
        </svg>
      </div> */}
      <div className="eco-title poppins-bold">
        How do we gamify
        <br /> user experience?
      </div>
      <div className="eco-game-content">
        <img src={EcoGameContentMobile} alt="eco game content" />
      </div>
      <img
        src={EcoGameMobileImg}
        alt="eco game mobile"
        className="eco-game-mobile-img"
      />
      <button className="primary-btn btn-create poppins-bold">
        Try it out now
        <img
          src={ArrowImg}
          className="eco-btn-arrow"
          style={{ marginLeft: "17px" }}
          alt="arrow"
        />
      </button>
    </div>
  ) : (
    <div className="C-Eco-Full-Section eco-game-container" ref={ecoGameRef}>
      <div className="eco-game__heading-container">
        {/* <div className="eco-game-logo">
          <img src={EcoGameLogo} alt="eco game logo" />
          <svg
            className={`eco-title-logo ${logoActive.game ? "active" : ""}`}
            width="172"
            height="21"
            viewBox="0 0 172 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.70381 11.4802C3.11553 9.38976 8.40946 7.77841 10.88 7.23403C22.1737 4.62107 40.3495 3.31446 47.9374 3.96776C55.5254 4.62107 47.2316 5.92747 39.9965 7.23398C32.7615 8.54048 38.0554 9.19374 44.5846 8.54048C51.1138 7.88723 64.7015 7.23398 76.3482 6.90735C87.9948 6.58072 76.1717 5.92747 64.7015 5.92747C53.2314 5.92747 53.4078 4.62102 57.4665 3.96776C61.5252 3.31451 70.7013 0.701497 81.4656 1.02812C92.23 1.35475 117.641 2.008 128.582 4.29439C139.522 6.58078 162.11 12.7867 167.58 14.7464C173.051 16.7062 172.345 22.2589 162.639 18.9926C152.934 15.7263 132.817 9.84704 88.7007 8.86716C44.5846 7.88728 11.0564 13.4399 7.52711 15.7263C3.99782 18.0127 1.52732 18.0127 2.93904 16.0529C4.35075 14.0932 4.70371 13.7666 3.29199 14.4198C1.88028 15.0731 -0.060828 14.0932 1.70381 11.4802Z"
              fill="#F9FBB3"
              stroke="#F9FBB3"
              className="svg-elem-1"
            ></path>
          </svg>
        </div> */}
        <h2 className="section-title eco-game__heading poppins-bold">
          Gamify your customer’s social experience rewarding actions with
          points.
        </h2>
      </div>
      <div className="eco-game-content">
        <div className="eco-game-left">
          <img src={EcoGameLeft} alt="eco game left" />
        </div>
        <div className="eco-game-right">
          <div className="eco-game-image">
            <img src={EcoGameRight} alt="eco game right" />
          </div>
          <button className="primary-btn btn-create poppins-bold">
            Try it out now
            <img
              src={ArrowImg}
              className="eco-btn-arrow"
              style={{ marginLeft: "17px" }}
              alt="arrow"
            />
          </button>
        </div>
      </div>
    </div>
  );
}
