import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/firestore";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBlBJtz1oV7_pWAyjrlkxdJ7ZenisHP5sk",
  projectId: "boltconcierge-2f0f9",
  databaseURL: "https://boltconcierge-2f0f9.firebaseio.com",
  storageBucket: "boltconcierge-2f0f9.appspot.com",
};

class Firebase {
  static initialize() {
    firebase.initializeApp(firebaseConfig);
  }
  //
  static storage() {
    return firebase.storage();
  }
  static getStorage() {
    return firebase.storage;
  }
  static firestore() {
    return firebase.firestore();
  }

  static async getAllRetailers() {
    const path = "retailers/all";
    const retailerSnapshot = await firebase.database().ref(path).once("value");

    const retailers = retailerSnapshot.val();
    return Object.keys(retailers).map((key) => ({
      ...retailers[key],
      id: key,
    }));
  }

  static getAllDeactiveRetailers = (callback) => {
    let path = "retailers/deactive";
    firebase
      .database()
      .ref(path)
      .once("value", (snapshot) => {
        let result = [];
        if (snapshot.val()) result = snapshot.val();
        callback(result);
      });
  };
}
Firebase.initialize();
export default Firebase;
