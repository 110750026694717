import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Firebase from "../../firebasehelper";
import React, { useEffect, useState, useRef } from "react";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import EcoChat from "../../components/EcoChat";
import EcoStore from "../../components/EcoStore";
import EcoGame from "../../components/EcoGame";
import EcoSocial from "../../components/EcoSocial";
import CTASection from "../../components/CTASection";
import EcoID from "../../components/EcoID";
import EcoBoost from "../../components/EcoBoost";
import Intro from "../../components/Intro";
import EcoReferrals from "../../components/EcoReferrals";
import EcoPay from "../../components/EcoPay";

export default function Landing() {
  const descriptionRef = useRef(null);
  const ecoReferralsRef = useRef(null);
  const ecoSocialRef = useRef(null);
  const ecoChatRef = useRef(null);
  const ecoGameRef = useRef(null);
  const ecoStoreRef = useRef(null);

  const navigate = useNavigate();

  const [isMobileView, setMobileView] = useState(false);
  const [lastScroll, setLastScroll] = useState(0);
  const [selectedBoostTab, setSelectedBoostTab] = useState(-1);
  const [currentTerritory, setCurrentTerritory] = useState("UK");
  const [topRetailers, setTopRetailers] = useState([]);
  const [retailers, setRetailers] = useState([]);
  const [logoActive, setLogoActive] = useState({
    intro: true,
    social: true,
    game: true,
    store: true,
    chat: true,
    referrals: true,
  });
  const [socialCardSelected, setSocialCardSelected] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  useEffect(() => {
    let timeoutIds = [];

    Object.entries(logoActive)
      .filter(([_field, value]) => value === false)
      .forEach(([field, _value]) => {
        const timeoutId = setTimeout(
          () => setLogoActive((prev) => ({ ...prev, [field]: true })),
          3000
        );
        timeoutIds.push(timeoutId);
      });
    return () => {
      timeoutIds.forEach((id) => clearTimeout(id));
    };
  }, [logoActive, setLogoActive]);

  useEffect(() => {
    const fetchRetailers = async () => {
      const allRetailers = await Firebase.getAllRetailers();
      setRetailers(allRetailers);
    };

    const checkDeviceStatus = () => {
      setMobileView(window.innerWidth < 1000);
    };

    fetchRetailers();
    checkDeviceStatus();
    window.addEventListener("resize", checkDeviceStatus);
  }, []);

  useEffect(() => {
    const filterRetailers = () => {
      const localRetailers = retailers
        .filter(
          (retailer) =>
            retailer.territory === currentTerritory &&
            retailer.top20 &&
            retailer.top20 !== "none"
        )
        .sort((prev, next) => prev.top20 - next.top20);

      let newRetailers = [];
      for (let i = 0; i < 28; i++) {
        newRetailers[i] = localRetailers[i % localRetailers.length];
      }
      setTopRetailers(newRetailers);
    };

    retailers.length && filterRetailers();
  }, [retailers, currentTerritory]);

  const onSocialCardSelect = (index) => {
    setSocialCardSelected((prev) => [
      ...prev.map((item, i) => (i === index ? !item : item)),
    ]);
  };

  const onGetStarted = () => {
    window.open("https://crm.ecosystem.co.uk/signup", "_blank");
  };

  useEffect(() => {
    const handleScroll = (_e) => {
      const curScroll = window.scrollY;
      if (curScroll) {
        const refs = [
          [ecoGameRef, "game"],
          [ecoSocialRef, "social"],
          [ecoChatRef, "chat"],
          [ecoStoreRef, "store"],
          [ecoReferralsRef, "referrals"],
        ];
        const check = (num, limit) => num >= 0 && num < limit;
        refs.forEach(([ref, field]) => {
          if (!ref || !ref.current) {
            return;
          }
          const height = ref.current.offsetHeight;
          if (
            check(curScroll - ref.current.offsetTop + 400, height) &&
            (!check(lastScroll - ref.current.offsetTop + 400, height) ||
              lastScroll === 0)
          ) {
            setLogoActive((prev) => ({ ...prev, [field]: false }));
          }
        });
        setLastScroll(curScroll);
      }
    };

    document.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScroll]);

  const scrollToRef = (ref, offset) => {
    offset = offset || 0;
    if (ref && ref.current) {
      window.scrollToY(
        ref.current.offsetTop - 70 - offset,
        1500,
        "easeInOutQuint"
      );
    }
  };

  const onClickTab = (index) => {
    if (index === 0) {
      // Social Tools
      setLogoActive((prev) => ({ ...prev, social: false }));
      scrollToRef(ecoSocialRef, isMobileView ? 40 : 50);
    } else if (index === 1) {
      // Chat Tools
      setLogoActive((prev) => ({ ...prev, chat: false }));
      scrollToRef(ecoChatRef, isMobileView ? -10 : 0);
    } else if (index === 2) {
      // Marketplace Tools
      setLogoActive((prev) => ({ ...prev, store: false }));
      scrollToRef(ecoStoreRef, isMobileView ? -10 : 0);
    } else if (index === 3) {
      // Gamification Tools
      setLogoActive((prev) => ({ ...prev, game: false }));
      scrollToRef(ecoGameRef);
    } else if (index === 4) {
      // Referral Tools
      setLogoActive((prev) => ({ ...prev, referrals: false }));
      scrollToRef(ecoReferralsRef, isMobileView ? -110 : 0);
    }
  };

  return (
    <div className="app-container">
      <Header
        isMobile={isMobileView}
        navigate={navigate}
        onClickTab={onClickTab}
      />
      <Intro
        descriptionRef={descriptionRef}
        logoActive={logoActive.intro}
        isMobileView={isMobileView}
        onGetStarted={onGetStarted}
      />
      <EcoBoost
        setSelectedBoostTab={setSelectedBoostTab}
        selectedBoostTab={selectedBoostTab}
        isMobileView={isMobileView}
      />
      <EcoSocial
        ecoSocialRef={ecoSocialRef}
        logoActive={logoActive}
        isMobileView={isMobileView}
        onSocialCardSelect={onSocialCardSelect}
        socialCardSelected={socialCardSelected}
      />
      {/* <EcoChat
        ecoChatRef={ecoChatRef}
        logoActive={logoActive}
        isMobileView={isMobileView}
      /> */}
      {/* <EcoID logoActive={logoActive} isMobileView={isMobileView} /> */}
      {/* <EcoStore
        isMobileView={isMobileView}
        ecoStoreRef={ecoStoreRef}
        logoActive={logoActive}
        onGetStarted={onGetStarted}
      /> */}
      <EcoGame
        isMobileView={isMobileView}
        logoActive={logoActive}
        ecoGameRef={ecoGameRef}
      />
      <EcoPay
        logoActive={logoActive}
        topRetailers={topRetailers}
        currentTerritory={currentTerritory}
        setCurrentTerritory={setCurrentTerritory}
        isMobileView={isMobileView}
      />
      {/* <EcoReferrals
        ecoReferralsRef={ecoReferralsRef}
        logoActive={logoActive}
        isMobileView={isMobileView}
      /> */}
      <CTASection onGetStarted={onGetStarted} isMobileView={isMobileView} />
      <Footer />
    </div>
  );
}
