import { useLocation } from "react-router-dom";
import "./index.scss";

const Articles = () => {
  const location = useLocation();
  const { pathname } = location;
  return (
    <iframe
      className="article__iframe"
      src={`https://ecosystem.life/ecosystem${pathname}`}
      title="ecosystem article"
    />
  );
};

export default Articles;
