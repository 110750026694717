import React from "react";
import EcoPayLogo from "../../assets/new/eco-pay/eco-pay-logo-yellow.png";
import EcoRetailersPhone from "../../assets/new/eco-pay/eco-retailers-phone.png";
import USA from "../../assets/new/usa.jpg";
import UK from "../../assets/new/uk.jpg";

// TODO: This file is a first step in orgranising this code base.
// Initially everything was just dumped into Landing/index.js which made it very hard to maintain.
// In the near future, we want to simplify this code and make this component responsive with media queries
// as opposed to using isMobileView to keep our code DRY.

export default function EcoPay({
  logoActive,
  currentTerritory,
  setCurrentTerritory,
  topRetailers,
  isMobileView,
}) {
  return isMobileView ? (
    <div className="C-EcoPay eco-pay-mobile-container">
      <div className="eco-pay-logo">
        <img src={EcoPayLogo} alt="eco pay logo" />
        <svg
          className={`eco-title-logo ${logoActive.token ? "active" : ""}`}
          width="134"
          height="24"
          viewBox="0 0 134 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.05063 13.1349C2.15509 10.7145 6.29681 8.84868 8.22961 8.21835C17.0653 5.19282 31.2852 3.6799 37.2216 4.43636C43.1581 5.19282 36.6694 6.70549 31.0091 8.21829C25.3487 9.73108 29.4904 10.4875 34.5985 9.73108C39.7067 8.97469 50.3371 8.21829 59.4489 7.84009C68.5607 7.46189 59.3108 6.70549 50.3371 6.70549C41.3634 6.70549 41.5014 5.19276 44.6767 4.43636C47.8521 3.67996 55.031 0.654365 63.4525 1.03256C71.874 1.41076 91.7543 2.16716 100.314 4.81456C108.873 7.46195 126.545 14.6477 130.825 16.9169C135.104 19.1861 134.552 25.6155 126.959 21.8335C119.366 18.0515 103.627 11.2439 69.1129 10.1093C34.5985 8.97475 8.36765 15.4041 5.6065 18.0515C2.84536 20.6989 0.912554 20.6989 2.01701 18.4297C3.12147 16.1605 3.39761 15.7823 2.29315 16.5387C1.18869 17.2951 -0.329942 16.1605 1.05063 13.1349Z"
            fill="#743AEC"
            stroke="#743AEC"
            className="svg-elem-1"
          ></path>
        </svg>
      </div>
      <div className="eco-pay-description">
        Spend points with retailers. <br />
        Generate value across all <br />
        walks of life with smooth <br />
        retail redemption.
      </div>
      <img
        className="eco-pay-mobile-img"
        src={EcoRetailersPhone}
        alt="eco retailers phone"
      />
      <div className="eco-pay-buttons">
        <div
          className="eco-pay-territory"
          style={{
            backgroundColor: currentTerritory === "UK" ? "#b6f0c5" : "white",
          }}
          onClick={() => setCurrentTerritory("UK")}
        >
          <img className="eco-pay-flag" src={UK} alt="UK" />
          <p className="poppins-regular">Where to spend</p>
        </div>
        <div
          className="eco-pay-territory"
          style={{
            backgroundColor: currentTerritory === "USA" ? "#b6f0c5" : "white",
          }}
          onClick={() => setCurrentTerritory("USA")}
        >
          <p className="poppins-regular">Where to spend</p>
          <img className="eco-pay-flag" src={USA} alt="USA" />
        </div>
      </div>
      <div className="eco-pay-grid-container">
        {topRetailers.map((retailer, id) => (
          <div
            className="eco-pay-icon"
            key={id}
            style={{ backgroundColor: "white" }}
          >
            <img src={retailer.ecopayLogo} alt={retailer.retailerID} />
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div className="C-EcoPay eco-pay-container">
      <div className="eco-pay-left">
        <img src={EcoRetailersPhone} alt="eco retailers phone" />
      </div>

      <div className="eco-pay-content">
        <div className="eco-pay-top">
          <div className="eco-pay-top-left">
            <div className="eco-pay-description">
              <h2 className="eco-section__title">
                Spend points with retailers
              </h2>
              <p className="eco-section__sub-title">
                Generate value across all walks of
                <br /> life with smooth retail redemption.
              </p>
            </div>
          </div>
          <div className="eco-pay-top-right">
            <div
              className="eco-pay-territory"
              style={{
                backgroundColor:
                  currentTerritory === "UK" ? "#b6f0c5" : "white",
              }}
              onClick={() => setCurrentTerritory("UK")}
            >
              <img className="eco-pay-flag" src={UK} alt="UK" />
              <p className="poppins-regular">Where to spend</p>
            </div>
            <div
              className="eco-pay-territory"
              style={{
                backgroundColor:
                  currentTerritory === "USA" ? "#b6f0c5" : "white",
              }}
              onClick={() => setCurrentTerritory("USA")}
            >
              <p className="poppins-regular">Where to spend</p>
              <img className="eco-pay-flag" src={USA} alt="USA" />
            </div>
          </div>
        </div>
        <div className="eco-pay-grid-container">
          {topRetailers.map((retailer, id) => (
            <div
              className="eco-pay-icon"
              key={id}
              style={{ backgroundColor: "white" }}
            >
              <img src={retailer.ecopayLogo} alt={retailer.retailerID} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
