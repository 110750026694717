import SpreadAndSplit from "../assets/new/eco-referral/01.png";
import SocialSharing from "../assets/new/eco-referral/01-1.png";
import ReviewsAndRewards from "../assets/new/eco-referral/01-2.png";
import LongFormArticles from "../assets/new/eco-referral/01-3.png";
import EcoSocialIcon from "../assets/new/eco-social/Icon.png";
import EcoSocialIcon1 from "../assets/new/eco-social/Icon-1.png";
import EcoSocialIcon2 from "../assets/new/eco-social/Icon-2.png";
import EcoSocialIcon3 from "../assets/new/eco-social/Icon-3.png";
import EcoSocialIcon4 from "../assets/new/eco-social/Icon-4.png";
import EcoSocialIcon5 from "../assets/new/eco-social/Icon-5.png";
import EcoSocialIcon6 from "../assets/new/eco-social/Icon-6.png";
import EcoSocialIcon7 from "../assets/new/eco-social/Icon-7.png";
import EcoSocialIcon8 from "../assets/new/eco-social/Icon-8.png";
import EcoStoreCommentImg1 from "../assets/new/eco-store/comment-1.png";
import EcoStoreCommentImg2 from "../assets/new/eco-store/comment-2.png";
import EcoStoreCommentImg3 from "../assets/new/eco-store/comment-3.png";
import EcoStoreIcon from "../assets/new/eco-store/01.png";
import EcoStoreIcon1 from "../assets/new/eco-store/01-1.png";
import EcoStoreIcon2 from "../assets/new/eco-store/01-2.png";
import EcoStoreIcon3 from "../assets/new/eco-store/01-3.png";

export const REFERRALS_CONTENT = [
  {
    imgSrc: SpreadAndSplit,
    imgAlt: "Improved ROA & CPA",
    title: "Improved ROA & CPA",
    text: "Harnessing your network delivers far leaner return than pay per click ads, as you grow by advocacy.",
  },
  {
    imgSrc: SocialSharing,
    imgAlt: "Share the wealth",
    title: "Share the wealth",
    text: "Members can share points and access to spend them, with friends and family for measurable organic referrals.",
  },
  {
    imgSrc: ReviewsAndRewards,
    imgAlt: "Gift insider content",
    title: "Gift insider content",
    text: "Enable insider tips, events, streams, articles and perks to be seeded out, enabling limited access to entice.",
  },
  {
    imgSrc: LongFormArticles,
    imgAlt: "Big up products",
    title: "Big up products",
    text: (
      <>
        Empower those with strong{" "}
        <br className="referrals--hide-on-desktop-sm" />
        experiences of your service to{" "}
        <br className="referrals--hide-on-desktop-sm" />
        share positive reviews and{" "}
        <br className="referrals--hide-on-desktop-sm" />
        access to VIP rates.
      </>
    ),
  },
];

export const ecoSocialData = [
  {
    icon: EcoSocialIcon,
    title: "Forum & Social Feeds",
    color: "#EFDAFF",
    description: (
      <>
        Your private social network grows user interaction{" "}
        <br className="eco-social--show-on-tablet" />
        through social feeds, hot topics and blog posts.
      </>
    ),
  },
  {
    icon: EcoSocialIcon1,
    title: "Polls & Surveys",
    color: "#BBFFFF",
    description: (
      <>
        Drive insights, feedback, touch points and customer{" "}
        <br className="eco-social--show-on-tablet" /> belonging. Let customers
        have their say.
      </>
    ),
  },
  {
    icon: EcoSocialIcon2,
    title: "Articles & Blogs",
    color: "#F9FBB3",
    description: (
      <>
        Educate and inform users with long-form articles
        <br className="eco-social--show-on-tablet" /> and blogs. Tag or
        cross-pollinate content.
      </>
    ),
  },
  {
    icon: EcoSocialIcon3,
    title: "Games & competitions",
    color: "#F9FBB3",
    description: (
      <>
        Set gamified tasks and games for your community,{" "}
        <br className="eco-social--show-on-tablet" /> rewarding actions or
        winners with points.
      </>
    ),
  },
  {
    icon: EcoSocialIcon4,
    title: "Marketplace & auctions",
    color: "#EFDAFF",
    description: (
      <>
        Social marketplace for auctions, subscriptions,{" "}
        <br className="eco-social--show-on-tablet" />
        offers, quick buys, group buys, and splitting.
      </>
    ),
  },
  {
    icon: EcoSocialIcon5,
    title: "Social Reviews",
    color: "#BBFFFF",
    description: (
      <>
        Rewarded social reviews for your services and{" "}
        <br className="eco-social--show-on-tablet" />
        products to drive insights and awareness.
      </>
    ),
  },
  {
    icon: EcoSocialIcon6,
    title: "Video & Streaming",
    color: "#BBFFFF",
    description: (
      <>
        Your private streaming platform for community wide{" "}
        <br className="eco-social--show-on-tablet" /> live streams, social
        videos and DM interaction.
      </>
    ),
  },
  {
    icon: EcoSocialIcon7,
    title: "Events & Appointments",
    color: "#F9FBB3",
    description: (
      <>
        Schedule digital and physical social events and{" "}
        <br className="eco-social--show-on-tablet" /> appointments. Sell
        tickets, RSVP and checkin.
      </>
    ),
  },
  {
    icon: EcoSocialIcon8,
    title: "Social Referrals",
    color: "#EFDAFF",
    description: (
      <>
        Drive organic growth through customer social{" "}
        <br className="eco-social--show-on-tablet" />
        networks via rewarding and slick referral UX.
      </>
    ),
  },
];

export const ecoStoreData = [
  {
    icon: EcoStoreIcon,
    title: "Spread and split",
    mobileTitle: "Spread and split",
    description:
      "Create product subscriptions with regular payments and allow your members to split costs between them.",
  },
  {
    icon: EcoStoreIcon1,
    title: "Social sharing and referrals",
    mobileTitle: (
      <>
        Social sharing
        <br />
        and referrals
      </>
    ),
    description:
      "Make it rewarding for your users to share products digitally via their social networks. Then incentivise the connections.",
  },
  {
    icon: EcoStoreIcon2,
    title: "Social reviews and rewards",
    mobileTitle: (
      <>
        Social reviews
        <br />
        and rewards
      </>
    ),
    description:
      "Drive feedback from testers and buyers of your products, rewarding their help in building trust and value for you.",
  },
  {
    icon: EcoStoreIcon3,
    title: "Use detailed long form articles",
    mobileTitle: (
      <>
        Use detailed long
        <br />
        form articles
      </>
    ),
    description:
      "Tag detailed articles to your product listings, to convert better with engaging, immersive and informing content.",
  },
];

export const ecoStoreComments = [
  EcoStoreCommentImg1,
  EcoStoreCommentImg2,
  EcoStoreCommentImg3,
];
