import React from "react";
import "./index.css";
import EcosystemTMBlack from "../../assets/new/ecosystem_tm_black.png";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer__top-container">
        <img src={EcosystemTMBlack} className="footer__logo" alt="logo" />
        <a
          href="https://www.linkedin.com/company/ecosystemlabs/"
          target="_blank"
          rel="noreferrer"
          className="footer__link"
        >
          <i className="fa fa-linkedin"></i>
        </a>
      </div>
      <div className="footer__bottom-container">
        <div className="footer__link-container">
          <a
            href="https://crm.ecosystem.co.uk/terms&conditions"
            target="_blank"
            rel="noreferrer"
            className="footer__link"
          >
            Terms & conditions
          </a>
          <a
            href="https://crm.ecosystem.co.uk/privacypolicy"
            className="footer__link"
            target="_blank"
            rel="noreferrer"
          >
            Privacy policy
          </a>
        </div>
        <p className="footer__text">All rights reserved</p>
      </div>
    </footer>
  );
}
