import EcoFreeImgMobile from "../../assets/new/eco-free-mobile.png";
import ArrowImg from "../../assets/new/Arrow.png";
import "./index.css";

export default function CTASection({ onGetStarted, isMobileView }) {
  return isMobileView ? (
    <section className="C-Eco-Full-Section eco-free-mobile-container">
      <div className="eco-free-mobile-top">
        <img src={EcoFreeImgMobile} alt="eco free mobile img" />
      </div>
      <div className="eco-free-mobile-content">
        <div className="eco-free-title poppins-regular">
          Active now
          <br /> <b>for free</b>
        </div>
        <div className="eco-free-description poppins-regular">
          Your private branded social platform, with insider access to gamified
          content and points to engage your community.
        </div>
        <button className="primary-btn btn-create poppins-bold">
          Create your Ecosystem
          <img
            src={ArrowImg}
            className="eco-btn-arrow"
            style={{ marginLeft: "17px" }}
            alt="arrow"
          />
        </button>
      </div>
    </section>
  ) : (
    <section className="eco-free">
      <div className="eco-free__img-container">
        <img
          src={EcoFreeImgMobile}
          alt="eco free img"
          className="eco-free__bg-img"
        />
      </div>
      <div className="eco-free__content">
        <div className="eco-free__title poppins-regular">
          Activate now <b>for free</b>
        </div>
        <div className="eco-free__description">
          Your private branded social platform, with insider
          <br />
          access to gamified content and points to engage your
          <br />
          community.
        </div>
        <button
          className="primary-btn btn-create poppins-bold"
          onClick={onGetStarted}
        >
          Create your Ecosystem
          <img
            src={ArrowImg}
            className="eco-btn-arrow"
            style={{ marginLeft: "17px" }}
            alt="arrow"
          />
        </button>
      </div>
    </section>
  );
}
