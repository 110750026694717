import ArrowImg from "../../assets/new/Arrow.png";
import EcoIntroBottomImg from "../../assets/new/eco-intro-bottom.png";
import EcoIntroMobileImg from "../../assets/new/eco-intro-picture-mobile.png";
import MobileCard from "../../assets/new/Group 1000001598.png";
import "./index.css";

// TODO: This file is a first step in orgranising this code base.
// Initially everything was just dumped into Landing/index.js which made it very hard to maintain.
// In the near future, we want to simplify this code and make this component responsive with media queries
// as opposed to using isMobileView to keep our code DRY.

export default function Intro({
  isMobileView,
  onGetStarted,
  descriptionRef,
  logoActive,
}) {
  return (
    <div className="introduction-container" ref={descriptionRef}>
      <div className="description">
        <div className="eco-title intro__heading-container">
          <h1 className="eco-title intro__heading poppins-bold">
            Rewarding social tools.
          </h1>
          {/* <svg
            className={`eco-title-logo ${logoActive ? "active" : ""}`}
            width="128"
            height="21"
            viewBox="0 0 128 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.52165 11.4802C2.56798 9.38976 6.49172 7.77841 8.32279 7.23403C16.6934 4.62107 30.1649 3.31446 35.7889 3.96776C41.4129 4.62107 35.2658 5.92747 29.9033 7.23398C24.5409 8.54048 28.4646 9.19374 33.3039 8.54048C38.1432 7.88723 48.2141 7.23398 56.8463 6.90735C65.4785 6.58072 56.7155 5.92747 48.2141 5.92747C39.7127 5.92747 39.8434 4.62102 42.8516 3.96776C45.8598 3.31451 52.661 0.701497 60.6392 1.02812C68.6175 1.35475 87.4514 2.008 95.5605 4.29439C103.67 6.58078 120.411 12.7867 124.465 14.7464C128.52 16.7062 127.997 22.2589 120.803 18.9926C113.61 15.7263 98.6995 9.84704 66.0017 8.86716C33.3039 7.88728 8.45356 13.4399 5.83774 15.7263C3.22192 18.0127 1.39084 18.0127 2.43717 16.0529C3.4835 14.0932 3.7451 13.7666 2.69877 14.4198C1.65244 15.0731 0.213739 14.0932 1.52165 11.4802Z"
              fill="#743AEC"
              stroke="#743AEC"
              className="svg-elem-1"
            ></path>
          </svg> */}
        </div>
        <div className="eco-introduction-description">
          <h2 className="intro__sub-heading">
            <span className="intro--hide-on-desktop">
              Your gamified social platform
              <br /> via bespoke app, site or api.
            </span>
            <span className="intro--hide-on-mobile">
              Your gamified social platform
              <br />
              via bespoke app, site or api.
            </span>
          </h2>
          <p className="intro__text">
            <span className="intro--hide-on-desktop"></span>

            <span className="intro--hide-on-mobile">
              Reward your customers for engaging with your <br />
              content, completing tasks and referring friends. <br />
              They earn points to spend with top retail brands.
            </span>
          </p>
          <p className="intro__text--mobile">
            Reward your customers for engaging <br />
            with your content, completing tasks
            <br />
            and referring friends. They earn points
            <br />
            to spend with top retail brands.
          </p>
        </div>
        <div className="intro__cta-container">
          <button
            className="primary-btn btn-create poppins-bold"
            onClick={onGetStarted}
          >
            Create your Ecosystem
            <img src={ArrowImg} style={{ marginLeft: "17px" }} alt="arrow" />
          </button>
          <span className="intro--hide-on-mobile intro__cta-text">
            It&apos;s free to get started.
          </span>
        </div>
        <div className="eco-intro-social">
          <img
            src={EcoIntroBottomImg}
            className="eco-introduction-bottom"
            alt="eco-intro-bottom"
          />
          <p
            className="intro__slogan poppins-regular"
            style={{ fontSize: "15px" }}
          >
            Plug-in and go, via iframe,
            <br />
            website, wallet or bespoke app.
          </p>
        </div>
      </div>
      <div className="instruction-img-container">
        <img
          className="header-image"
          src={isMobileView ? EcoIntroMobileImg : MobileCard}
          alt="introducton"
        />
      </div>
    </div>
  );
}
