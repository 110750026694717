import React from "react";
import { Route, Routes } from "react-router-dom";
import Landing from "./screens/Landing";
import Articles from "./screens/Articles";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Routes>
        <Route path="/articles" element={<Articles />} />
        <Route path="/magazine" element={<Articles />} />
        <Route path="/" element={<Landing />} />
      </Routes>
    );
  }
}
